//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const brand = "ZBXN";
export default {
  name: "InfoDetail",
  data() {
    return {
      tab: "basic",
      basicInfo: {},
      custInfo: {},
      dic1: [
        { id: "SAP_0000000375", txt: "从事行业", value: "" },
        { id: "SAP_0000000110", txt: "职业", value: "" },
        { id: "SAP_0000000111", txt: "其它职业", value: "" },
        { id: "SAP_0000000116", txt: "年收入", value: "" },
        { id: "SAP_0000000133", txt: "肤色", value: "" },
        { id: "SAP_0000000134", txt: "身高", value: "" },
        { id: "SAP_0000000108", txt: "结婚纪念日", value: "" },
        { id: "ZMARRY", txt: "婚姻状况", value: "" },
        { id: "ZCONTACT", txt: "接受联系", value: "" },
      ],
      dic2: [
        { id: "MFASHION", txt: "VIP穿衣风格", value: "" },
        { id: "MCON", txt: "VIP消费能力", value: "" },
        { id: "SAP_0000000112", txt: "上衣号码", value: "" },
        { id: "SAP_0000000113", txt: "裤装号码", value: "" },
        { id: "SAP_0000000114", txt: "鞋号", value: "" },
        { id: "SAP_0000000130", txt: "其他爱好", value: "" },
        { id: "SAP_0000000131", txt: "家庭成员数", value: "" },
      ],
      dic3: [
        { id: "SAP_0000000063", txt: "首购原因", type: "checkbox" },
        { id: "SAP_0000000066", txt: "首购服装类型", type: "checkbox" },
        { id: "SAP_0000000071", txt: "喜欢穿着的男装品牌", type: "checkbox" },
        { id: "SAP_0000000135", txt: "体型", type: "radio" },
        { id: "SAP_0000000136", txt: "着装风格", type: "checkbox" },
        { id: "SAP_0000000109", txt: "沟通方式偏好", type: "checkbox" },
        { id: "SAP_0000000118", txt: "爱好", type: "checkbox" },
        { id: "SAP_0000000377", txt: "获取品牌渠道", type: "checkbox" },
        { id: "SAP_0000000378", txt: "消费类型", type: "radio" },
        // {id:'SAP_0000000379',txt:'购买习惯',type:'radio'},
        { id: "SAP_0000000365", txt: "喜欢的礼品", type: "checkbox" },
        { id: "SAP_0000000380", txt: "喜爱的电影", type: "checkbox" },
        { id: "SAP_0000000381", txt: "感兴趣的活动", type: "checkbox" },
        // {id:'SAP_0000000382',txt:'喜欢的杂志',type:'checkbox'},
        { id: "SAP_0000000383", txt: "经常关注的信息", type: "checkbox" },
        // {id:'SAP_0000000384',txt:'喜欢的运动方式',type:'checkbox'},
        // {id:'SAP_0000000385',txt:'喜爱的饮品',type:'checkbox'},
        // {id:'SAP_0000000386',txt:'喜爱的饮食',type:'checkbox'},
        // {id:'SAP_0000000387',txt:'喜爱的公益方式',type:'checkbox'},
        // {id:'SAP_0000000388',txt:'喜爱的投资',type:'checkbox'}
      ],
      dic3Map: {},
      activeItem: "",
      couponStatus: "1",
      couponlist: [],
      saleAnalysisData: {
        firstTime: "",
        lastTime: "",
        fromToday: "",
        saletimes: 0,
        saleNum: 0,
        saleAmount: 0,
        perAmount: 0,
        pieceAmount: 0,
        saleRate: "",
        saleList: {},
      },
      pieDataStyle: null,
      pieDataCategory:null
    };
  },
  created() {
    window.document.title = this.$route.query.name;

    this.getSaleAnalysis();
  },
  mounted() {
    window.document.title = this.$route.query.name;
    this.wxConfig();
  },
  activated() {
    window.document.title = this.$route.query.name;
  },
  methods: {
    getBasicInfo() {
      const that = this;
      this.currentMobile = null;
      // this.axios.get('/crm/customer/getMemberByBpid', {
      this.axios
        .get(
          "/" +
            this.$ajaxPrefix.consumer +
            "/v1/wx/cp/crm/customer/getMemberByBpid",
          {
            params: {
              brand: brand,
              bpid: that.$route.query.bpid,
            },
          }
        )
        .then((res) => {
          that.basicInfo = res.data.data ? res.data.data : {};
          that.basicInfo.birthday = that.basicInfo.birthday ? this.$moment(that.basicInfo.birthday).format("MM-DD") : '';
          that.currentMobile = that.basicInfo.mobile
            ? that.basicInfo.mobile
            : null;
          that.getCustInfo();
        });
    },
    getCustInfo() {
      const that = this;
      this.custInfo = {};
      if (!that.currentMobile) return;
      this.axios
        .get(
          "/" +
            this.$ajaxPrefix.consumer +
            "/v1/wx/cp/crm/consume/getCustConsumeInfoByMobile",
          {
            params: {
              mobile: this.currentMobile,
            },
          }
        )
        .then((res) => {
          if (res && res.data && res.data.data && res.data.data.consumeItems) {
            const temp = res.data.data.consumeItems;
            let keys = new Set();
            const custObj = {};
            temp.forEach((item) => {
              keys.add(item.rlbNum);
            });
            const keyArr = Array.from(keys);
            keyArr.forEach((item) => {
              custObj[item + ""] = [];
            });
            temp.forEach((item) => {
              item.consumeDate = this.$moment(item.consumeDate).format("YYYY-MM-DD");
              custObj[item.rlbNum + ""].push(item);
            });

            that.custInfo = custObj;
          }
        });
    },
    getDetail() {
      const that = this;
      const dic3Map = this.dic3Map;
      this.axios
        .get(
          "/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/crm/customer/getDetail",
          {
            params: {
              bpid: that.$route.query.bpid,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res && res.data && res.data.data) {
            const temp = res.data.data;
            temp.forEach((item) => {
              that.dic1.forEach((item1) => {
                if (item1["id"] == item["zkey"]) {
                  item1["value"] == item["zvalue"];
                }
              });
              that.dic2.forEach((item1) => {
                if (item1["id"] == item["zkey"]) {
                  item1["value"] == item["zvalue"];
                }
              });
              for (let key in dic3Map) {
                if (item["zkey"] == key) {
                  dic3Map[key].forEach((_item) => {
                    if (_item.value == item["zvalue"]) {
                      _item.selected = "checked";
                    }
                  });
                }
              }
            });
            that.dic3Map = dic3Map;
            console.log(that.dic3Map);
          }
        });
    },
    getAllPortrait() {
      const that = this;
      const dic3Map = {};
      this.dic3.forEach((item) => {
        dic3Map[item.id] = [];
      });
      this.axios
        .get(
          "/" +
            this.$ajaxPrefix.consumer +
            "/v1/wx/cp/crm/customer/getAllPortrait"
        )
        .then((res) => {
          if (res && res.data && res.data.data) {
            const temp = res.data.data;
            temp.forEach((item) => {
              that.dic3.forEach((item1) => {
                if (item.eigenName == item1.id) {
                  dic3Map[item1.id].push({
                    value: item.eigenvalue,
                    txt: item.eigenvalueText,
                    selected: "",
                  });
                }
              });
            });
            that.dic3Map = dic3Map;
            that.getDetail();
          }
        });
    },
    getCouponList() {
      this.couponlist = [];
      const that = this;
      this.axios
        .get(
          "/" +
            this.$ajaxPrefix.consumer +
            "/v1/wx/cp/crmcoupon/couponListByBpid",
          {
            params: {
              bpid: that.$route.query.bpid,

              status: that.couponStatus,
            },
          }
        )
        .then((res) => {
          console.log("getCouponList1", res.data);
          if (res && res.data && res.data.data) {
            res.data.data.forEach((item) => {
              if(item && item.end_date){
                item.end_date = this.$moment(item.end_date).format("YYYY-MM-DD");
              }
            });
            this.couponlist = res.data.data;
          }
        });
    },
    getCustomerPie() {
      const that = this;
      var pdata = [{
                'PARAMETER': 'ZDATE_001',
                'SIGN': 'I',
                'OPTION': 'BT',
                'LOW': this.saleAnalysisData.firstTime.split('-').join(''),
                'HIGH': this.saleAnalysisData.lastTime.split('-').join(''),
                
            }, {
                'PARAMETER': 'ZPP_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': '10',
                "LOW_FLAG":"10",
                'HIGH': ''
            }, {
                'PARAMETER': 'ZBPARTNER_002',
                'SIGN': 'I',
                'OPTION': 'EQ',
                "LOW_FLAG":"0",
                'LOW': that.$route.query.bpid,
                'HIGH': ''
            }]
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer +
            "/bw/BwApi/getCustomerPie",
          pdata
        )
        .then((res) => {
          console.log("getCustomerPie", res.data);
          if (res && res.data && res.data.data && res.data.data.length > 0) {
            this.pieDataStyle = this.showPieChart(
              "#styleChart",
              this.buildSeries(res.data.data, "style"),false,240
            );
            this.pieDataCategory = this.showPieChart(
              "#categoryChart",
              this.buildSeries(res.data.data, "category"),false,240
            );
          }
        });
    },
    buildSeries(datas, filed) {
      var series = {}
      var seriesInfo = []
      datas.map(function(item) {
        if(series[item[filed]]){
          series[item[filed]]++
        }else{
          series[item[filed]] = 1
        }
      });
      for(var item in series){
        if(item){
          seriesInfo.push({ name: item, y: Number(series[item]) })
        }
      }
      console.log("series",series,seriesInfo);
      return seriesInfo;
    },
    showPieChart(name, series, showLabel, height) {
      var showLabel = showLabel || false;
      return {
          credits: {
              enabled: false
          },
          chart: {
            animation: false,
              height: height,
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie',
              events: {
                click: function() {
                    return false;
                }
              }
          },
          tooltip: {
            enabled: false
          },
          title: {
              text: null
          },
         plotOptions: {
          pie: {
             colors: [
              "#37a2da","#32c4e9","#64e1e3","#fedc5e", "#fd9e82","#38a1db","#29c9e9", "#69dfe3", "#90f4dc" 
            ],
            point: {
                events: {
                    legendItemClick: function() {
                        return false;
                    }
                }
            },
            dataLabels: {
              distance: 16,
            },
            allowPointSelect: false,
            cursor: 'pointer',
            enableMouseTracking: false,
          },
        },
        series: [{
          name:'',
          animation: false,
          allowPointSelect: false,
          size:'50%',
          innerSize: "70%",
          data: series,
          events: {
            legendItemClick: function() {
                return false;
            }
          }
        }]
      };
  },
    getSaleAnalysis() {
      const that = this;
      this.axios
        .get(
          "/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/customer/xffxByBpid",
          {
            params: {
               bpid: that.$route.query.bpid,
            //  bpid: "1000898493",
            },
          }
        )
        .then((res) => {
          console.log("getSaleAnalysis", res.data);
          if (res && res.data && res.data.data) {
            this.ctreateSaleResult(res.data.data);
            this.getBasicInfo();
            this.getAllPortrait();
            this.getCouponList();
            this.getCustomerPie();
          } else {
            this.$dialog
              .alert({
                message: res.data.msg,
              })
              .then(() => {
                 this.$router.go(-1)
              });
          }
        });
    },
    ctreateSaleResult(data) {
      if(data.consumeItems.length==0)
      {
        return
      }
      data.consumeItems.sort(function (a, b) {
        return a.consumeDate - b.consumeDate;
      });
      console.log("ctreateSaleResult", data.consumeItems);
      this.saleAnalysisData.firstTime = this.$moment(
        data.consumeItems[0].consumeDate
      ).format("YYYY-MM-DD");
      this.saleAnalysisData.lastTime = this.$moment(
        data.consumeItems[data.consumeItems.length - 1].consumeDate
      ).format("YYYY-MM-DD");
      this.saleAnalysisData.fromToday = this.$moment().diff(
        this.$moment(
          data.consumeItems[data.consumeItems.length - 1].consumeDate
        ),
        "days"
      ); // 1
      this.saleAnalysisData.saletimes = data.consumeItems.length;
      var consumeByyears = {};
      for (let index = 0; index < data.consumeItems.length; index++) {
        this.saleAnalysisData.saleNum += Number(data.consumeItems[index].qty);
        this.saleAnalysisData.saleAmount += Number(
          data.consumeItems[index].price
        );
        this.saleAnalysisData.perAmount =
          Math.floor(
            (this.saleAnalysisData.saleAmount /
              this.saleAnalysisData.saletimes) *
              100
          ) / 100;
        this.saleAnalysisData.pieceAmount =
          Math.floor(
            (this.saleAnalysisData.saleAmount / this.saleAnalysisData.saleNum) *
              100
          ) / 100;
        var years = this.$moment(data.consumeItems[index].consumeDate).format(
          "YYYY"
        );

        if (consumeByyears[years]) {
          consumeByyears[years].qty += Number(data.consumeItems[index].qty);
          consumeByyears[years].saleNum += 1;
          consumeByyears[years].price += Number(data.consumeItems[index].price);
        } else {
          consumeByyears[years] = {
            qty: Number(data.consumeItems[index].qty),
            saleNum: 1,
            price: Number(data.consumeItems[index].price),
            year: years,
          };
        }
      }
      console.log("consumeByyears", consumeByyears);
      this.saleAnalysisData.saleList = consumeByyears;
    },
  },
};
